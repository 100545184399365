import React  from 'react';

import { Container } from './Container'
import { Logo } from './Logo'
import { NavLink } from './NavLink'

export function Footer() {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-4">
          {/* <Logo className="mx-auto h-10 w-auto" /> */}
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <NavLink href="/#product">Product</NavLink>
              <NavLink href="/#aboutus">About us</NavLink>
              <NavLink href="/#faq">FAQ</NavLink>
              <NavLink href="/terms">Terms</NavLink>
              <NavLink href="/pdpa">PDPA</NavLink>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            <a
              href="https://twitter.com"
              className="group"
              aria-label="INFINYX on Facebook"
            >
              <svg viewBox="0,0,256,256" >
                <path d="M25,3c-12.15,0 -22,9.85 -22,22c0,11.03 8.125,20.137 18.712,21.728v-15.897h-5.443v-5.783h5.443v-3.848c0,-6.371 3.104,-9.168 8.399,-9.168c2.536,0 3.877,0.188 4.512,0.274v5.048h-3.612c-2.248,0 -3.033,2.131 -3.033,4.533v3.161h6.588l-0.894,5.783h-5.694v15.944c10.738,-1.457 19.022,-10.638 19.022,-21.775c0,-12.15 -9.85,-22 -22,-22z"></path>
                </svg>
        
            </a>
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} DLC. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}
