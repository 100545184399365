import React  from 'react';
import '../index.css';
import '../main.css';
import { Container } from '../components/Container';

function PDPA() {
  return (
    <>
    <Container>
    <p className="mt-16">
Updated 25 May 2022<br/>

DigitaLife Corporation Limited<br/><br/>

 

This policy ("Privacy Policy") describes how DigitaLife Corporation  ("DLC") manages Personal Data which is in our possession or under our control. "Personal Data", is defined in the Personal Data Protection Act B.E. 2562 (2019) of Thailand (“PDPA”), and currently refers to “any information relating to a person which can directly or indirectly identify such person but excludes those of a deceased person or anonymized data”. <br/><br/>

 

By using our services pursuant to your agreement(s) with us ("Services"), operating any account maintained with us, accessing the online platform operated by us (which is accessible through our website at www.infinyx.com or through our mobile application) ("Platform"), websites or mobile applications, or otherwise providing information to or communicating with us, you are taken to have agreed to our collection, use, processing and disclosure of your Personal Data in accordance with this Privacy Policy.<br/><br/>

 

This Privacy Policy does not supersede or replace any other consents you may have previously or separately provided to us in respect of your Personal Data, and your consent to this Privacy Policy is in addition to any other rights which we may have at law to collect, use, process or disclose your Personal Data. <br/><br/>

 

The words "we", "us", "our" or any of their derivatives refer to DLC and its successors and any novatee, assignee, transferee or purchaser of DLC's rights and/or obligations hereunder and any reference to DLC includes a reference to such successor, novatee, assignee, transferee or purchaser. The words "you", "your", "yours" or any of their derivatives refer to the person using our Services, operating any account maintained with us, accessing our Platform, website or mobile applications, or otherwise providing information to or communicating with us and shall include, as the context may require, personal representatives (as the case may be).<br/><br/>

 

This Privacy Policy shall be governed by, and construed in accordance with, the laws of Thailand. Without prejudice to your rights under any applicable laws, any dispute arising out of or in connection with this Privacy Policy and/or the documents referred to herein, including any question regarding their existence, validity or termination, shall be referred to and finally resolved by the Courts of Thailand and both you and we hereby unconditionally and irrevocably submit to the exclusive jurisdiction of the Courts of Thailand.<br/><br/>

 

What Personal Data we Collect<br/><br/>

 

We collect, use, disclose, transfer and otherwise process Personal Data about you or individuals who are connected or associated with you including but not limited to your legal representatives (“Associated Persons”) in accordance with this Privacy Policy.<br/><br/>

 

The Personal Data we collect or may collect include:<br/>

1.Personal contact data including name, residential addresses, email address, and telephone numbers;<br/>

2.Occupation, education, and income levels;<br/>

3.Identification card or passport number, date of birth, place of birth and any other information for the verification of identity;<br/>

4.Account data including log-in details, email address, and other information provided through your account);<br/>

5.financial and banking information (e.g. information on net assets, income, expenses, credit history, bank account and banking transactions, securities trading account); <br/>

6.images and voice recordings of our conversations with you; <br/>

7.tax and insurance information; <br/>

8.information about your risk profile, investments, investment objectives, knowledge and experience and/or business interests and assets; <br/>

9.personal opinions made known to us (e.g. your feedback or responses to any surveys); <br/>

10.browsing history, patterns or other unique information;<br/> 

11.information on how you use our App and Services including the type of content that you view or engage with, the features you use, the types of actions your take, the people or accounts you interact with, and the time, frequency and duration of your activities.<br/>

12.information about any transactions on our App or when you use the services including any information collected by us or any third party that any financial transactions (such as an in-app purchase or donation). This information includes payment information such as your credit or debit card number or other card or payment details, your bank account number, authentication information and billing and contact details;<br/>

13.where you sign up or log in with your account on a third party site (such as your Facebook account, Google account, or Apple ID), the personal data that you have provided to the relevant third party site, to the extent allowed by your account settings with the third party site or otherwise authorized by you;<br/>

14.information relating to your activities, habits, preferences and interests arising from your use of the App, Services or other products and services we, our partners or our vendors provide;<br/>

15.your internet protocol address and information associated with such address;<br/>

16.any other Personal Data reasonably required in order for us to provide the services requested by you; and<br/>

17.any other Personal Data required to comply with any applicable local or foreign laws, rules, acts, regulations, subsidiary legislation notices, notifications, circulars, licence conditions, directions, requests, requirements, guidelines, directives, codes, information papers, practice notes, demands, guidance and/or decisions of any national, state or local government, any agency, exchange, regulatory or self-regulatory body, law enforcement body, court, central bank or tax revenue authority or any other governmental organisation whether in Thailand or elsewhere, with the relevant authority (including any intergovernmental agreement between the governments or regulatory authorities of two or more jurisdictions or otherwise), as may be amended from time to time ("Applicable Laws") and our internal control and compliance policies.<br/>
<br/>
 

Additionally, the App makes use of third-party libraries, APIs and software which may also collect additional information. Please refer to the relevant third party’s privacy policy for more information on the third party’s processing of your data.<br/>  

 
<br/>
How we collect your personal data<br/><br/>

 

The Personal Data has/or will be obtained from the following sources, where applicable, or such other sources which we may see fit from time to time:
<br/>
 

information provided or submitted by you through among others, your dealings and agreements with us, which includes information provided when registering as a user, providing information regarding any account which you may open with us, providing answers to security questions, completing any confirmations, declarations or forms, or through your utilization of any of our Services, accessing or viewing our Platform;
<br/>
through visiting our offices, phone conversations, or attending our events;
<br/>
as applicable, publicly available or publicly accessible information; and
<br/>
such other written, electronic or verbal communications or documents delivered to us prior to and during the course of our contractual or pre-contractual dealings with you.
<br/><br/>
 

Purpose of use of Personal Data and How we process your personal data
<br/><br/>
 

DLC may collect, use, and disclose your personal data for our core business purposes such as:
<br/><br/>
 

to develop, provide, and administer the App and Services and enabling your access and use of the App and Services;
<br/>
carrying out any transactions on your behalf contemplated on the Platform and the Services thereto;
<br/>
assessing and processing applications, instructions or requests from you;
<br/>
communicating with you, including providing you with updates on changes to our Services;
<br/>
to verify your identity for the purposes of providing Services to you;
<br/>
conducting due diligence checks, screenings or credit checks as may be required by any Applicable Laws or our internal policies and procedures;
<br/>
to administer any account which you may open with us;
<br/>
to process payments;
<br/>
to respond to queries or feedback;
<br/>
for the specific purpose for which it was volunteered or provided to us;
<br/>
any other reasonable purposes in connection with the provision of our Services;
<br/>
to detect and protect us or any third parties against negligence, fraud, theft and other illegal activities;
<br/>
to understand your needs and preferences;
<br/>
to develop and personalise the App and Services or such other products and services that we may provide you;
<br/>
to maintain and administer any software updates and/or other updates and support that may be required from time to time to ensure the smooth running of the App and Services;
<br/>
to tailor your experience through the Services by displaying content according to your interests and preferences;
<br/>
to provide targeted marketing and advertisements via electronic transmission in the App or to your email address(es);
<br/>
to conduct research, analysis, and development activities (including, but not limited to, data analytics, surveys, product and service development and/or profiling), to monitor and analyse how you use the App and Services, to improve the App and Services and/or to enhance your user experience.
<br/>
administering benefits or entitlements in connection with our relationship with you, the use of the App and the Services;
<br/>
improving the content, appearance and utility of the Platform;
<br/>
to manage and develop infrastructure and business operations;
<br/>
to comply with our internal policies and procedures;
<br/>
to address or investigate any complaints, claims or disputes;
<br/>
as permitted by any Applicable Laws;
<br/>
enforcing obligations owed to us;
<br/>
seeking professional advice, including legal advice;
<br/>
fulfilling any purpose directly related to the above Purposes;
<br/>
any other purposes that are appropriate or authorized by any Applicable Laws;
<br/>
to comply with any Applicable Laws or any request from any relevant governmental or regulatory authority;
<br/>
financial reporting, regulatory reporting, management reporting, risk management, audit and record keeping purposes; or
<br/>
with your consent, providing you with marketing materials in connection with the services we may provide.
<br/>
<br/>

We only collect the information of children, quasi-incompetent persons, and incompetent persons where their parent or guardian has given their consent. We do not knowingly collect information from customers under the age of 20 without their parental consent when it is required, or from quasi-incompetent persons and incompetent persons without their legal guardian's consent. In the event we learn that we have unintentionally collected personal information from anyone under the age of 20 without parental consent when it is required, or from quasi-incompetent persons and incompetent persons without their legal guardians, we will delete it immediately or process only if we can rely on other legal bases apart from consent.
<br/>
<br/>

The Services are not intended for children under the age of 13. We do not knowingly collect or maintain any personal data or non-personally-identifiable information from anyone under the age of 13 nor is any part of our Platform or other Services directed to children under the age of 13.  As a parent or legal guardian, please do not allow such children under your care to submit personal data to us. In the event that personal data of a child under the age of 13 in your care is disclosed to us, you hereby consent to the processing of the child’s personal data and accept and agree to be bound by this Policy on behalf of such child.  We will close any accounts used exclusively by such children and will remove and/or delete any personal data we believe was submitted without parental consent by any child under the age of 13.
<br/>
<br/>

We may also process your personal data for other purpose if we have obtained your consent, or the processing is permitted or required by laws, including (i) to comply with our legal obligations under Laws (ii) to prevent or suppress a danger to life, body, health of person (iii) to perform a task carried out in the public interest or in the exercise of official authority (iv) for legitimate interests pursued by DLC or by a third party with the reasonable necessity and employee’s expectations. (For example, to sell any part of our business or its assets or to enable the acquisition of our businesses or assets, in whole or part, by third parties, to prevent fraud or criminal activity, misuses of our products or services as well as the security of our IT systems, architecture, and networks).
<br/>
<br/> 

Notwithstanding the foregoing, we may use your anonymized data for any purpose without your consent.
<br/>
<br/> 

Whom we share and disclose your personal data with
<br/>
<br/> 

DLC will not share, sell or otherwise disclose your personal data to a Third Party without your consent, except as permitted or required by Laws and to the extent, the disclosure serves the purposes as abovementioned.
<br/>
<br/> 

In the course of our business activities and to fulfill the purposes, your personal data may be disclosed, either in Thailand or outside Thailand, to the potential recipients, (in each case including its respective employees and director), as follows;
<br/>
<br/> 

Other entities in DLC (as shown in the Table) : when providing personal data to one entity in DLC may result in that data being accessible by all entities in DLC. The Disclose will require to conduct activities in a manner consistent with the Binding Corporate Rules and relevant guidelines in relation to personal data protection.
<br/>
​<br/>

       Digitalife Corporation Limited
<br/>
       Magnolia Quality Development Corporation Limited
<br/>
       DTGO Corporation Limited
<br/>
       Dee Supreme Corporation Limited
<br/>
       V Before Me Corporation Limited
<br/>
       MQDC Brite Corporation Limited
<br/>
       Whizdom Society Development Corporation Limited
<br/>
       Whizdom Pinnacle Corporation Limited
<br/>
       Whizdom Landmark Corporation Limited
<br/>
       Whizdom Club Corporation Limited
<br/>
       Whizdom Wealth Development Corporation Limited
<br/>
       Whizdom Asset Corporation Limited
<br/>
       Whizdom Quality Development Corporation Limited
<br/>
       The Iconsiam Superlux Residence Corporation Limited
<br/>
       The Estate (Thailand) Limited
<br/>
       The Worldmark Grand Hospitality Company Limited
<br/>
       The Worldmark Company Limited
<br/>
       Mabel Green Corporation Limited
<br/>
       ICS Company Limited
<br/>
       MQ Retail Experience Corporation Limited
<br/>
       Nebula Corporation Limited
<br/>
       MQDC Town Corporation Limited
<br/>
       MQDC Proud Estate Limited
<br/>
       MQDC Town Royal Residences Limited
<br/>
       MQDC Town Royal Asset Limited
<br/>
       MQDC Town Royal Place Limited
<br/>
       MQDC Town World Limited
<br/>
       MQDC Town Collection Limited
<br/>
       Royal Aspen MQDC Town Limited
<br/>
       MQDC Social Enterprises Limited
<br/>
       Siam River Holdings Limited
<br/>
       ICONSIAM Signature Company Limited
<br/>
       The Aspen Corporation Limited
<br/>
       ONE.SIX Development Corporation Limited
<br/>
       The Strand Corporation Limited
<br/>
       Whizdom Elegant Place Limited
<br/>
       Mulberry Grove Corporation Limited
<br/>
       MQDC Corporation Limited
<br/>
       Magnolias Corporation Limited
<br/>
       Mabel Green Smart Living Corporation Limited
<br/>
       Green Wealth Corporation Limited
<br/>
       MQ Asset Holding Company Limited
<br/>
       The Chaopraya River Assets Holdings Co.,Ltd.
<br/>
       Mulberry Grove Sukhumvit Corporation Limited
<br/>
       Sukhumvit Digital Parks Limited
<br/>
       Magnolia Finest Corporation Limited
<br/>
       Magnolia International Corporation Limited
<br/>
       Magnolia Hotel & Resorts Company Limited
<br/>
       MQDC Courtesy Service Corporation Limited
<br/>
​<br/>

​

1.our supplier or service providers (IT systems providers, cloud service providers, database providers and consultants providing services in relation to any matter on which DLC is instructed) where disclosure to that provider of services is considered necessary to fulfill the purposes set out above;
<br/>
2.our business partners who offer products or services jointly with us or with our subsidiaries or affiliates;
<br/>
3.potential parties with whom DLC intends to merge or sell the whole or a part of the DLC; or
<br/>
4.the government authority or government officer by virtue of the provisions of specific laws to maintain the stability of the State, or to maintain the public order or good moral, and fully comply with the procedures as prescribed by such laws.
<br/>
<br/> 

Where DLC discloses or transfers personal data to any third parties, other than DLC entities, DLC will enter into an agreement with the third party setting out the respective obligations of each party in place to protect data against unauthorized or accidental use, access, disclosure, damage, loss or destruction.
<br/>
<br/>

Retaining your Personal Data
<br/>
<br/>

We will only retain the personal data for a period not exceeding 10 years or as long as necessary to fulfill the purpose (as set out above) for which that we collected it, and to the extent permitted by Personal Data Protection Act B.E. 2562 or other applicable laws. When we no longer need to use your personal data, and we will remove it from our systems and records and/or take steps to anonymize it so that you can no longer be identified from it.
<br/>
 <br/>

Your privacy rights and how you can exercise your rights
<br/>
 

Under Personal Data Act B.E. 2562, you may have the following rights that you may exercise in relation to your Personal Data processed by us, as follows:<br/>
<br/>
1.the right to receive a copy of your personal data processed by us and obtain other information about how do we collect/process your personal data and why we process your personal data. Your personal data will generally be provided to you in electronic form;
<br/>
2.the right to rectification of inaccurate, incomplete, or misleading Personal Data relating to you;
<br/>
3.the right to request the erasure, destructive, anonymization of your personal data;
<br/>
4.the right to request to restrict the processing of your Personal Data;
<br/>
5.the right to ask that we transfer the personal data to another data controller (or ask us to do so if technically feasible) where our lawful basis for processing the personal data is consent or necessity for the performance of our contract with you and the processing is carried out by automated means; and
<br/>
6.the right to object to any processing which is based on our legitimate propose or other legal ground;
<br/>
7.the right to withdraw your consent, at any time, where we process personal data based on consent at any time. However, we may continue processing your personal data based on another legal basis.
<br/>
<br/>

However, based on legal grounds as specified in Personal Data Protection Act B.E. 2562, we may not be required by laws to complete your request, as such rights may be conditional or because we have to balance your rights against our rights and obligations to process your Personal Data and to protect the rights and freedoms of others.
<br/>
<br/> 

Withdrawal and Consequences
<br/>
<br/> 

You are entitled to withdraw your consent at any time but such withdrawal will not affect the validity of the processing made prior to the withdrawal of consent or processing based on another legal basis.
<br/>
<br/>

Your withdrawal of consent or refusal to provide certain information may result in us being unable to fulfill some or all of the objectives stated in this privacy notice.
<br/>
<br/> 

How do we protect your Personal Data
<br/>
<br/>

DLC have implemented (have required the third party to implement) an appropriate technical, and physical measures to safeguard and protect Personal Data from unauthorized or unlawful Processing and against accidental or unlawful destruction, loss or alteration or damage, unauthorized disclosure of, or access to, Personal Data.
<br/>
<br/>

Change to this Privacy Policy
<br/>
<br/> 

We reserve the right to update this policy for any reason, including but not limited to complying with relevant the Law of Thailand, Thailand government policy, regulatory and other obligations of a similar nature. Any updates will appear on our website at www.infinyx.com/privacy-policy/. Any changes to this Privacy Policy will become effective upon publishing Privacy Policy. 
<br/>
<br/>

How do you Contact Us
<br/>
<br/> 

If you have any question or would like to exercise your data subject rights, you may contact us at the following channels:
<br/>
<br/> 

Attn: Personal Data Protection Office
<br/>
Address: Digitalife Corporation Limited, No. 695, Sukhumvit 50, Sukhumvit Road, Prakanong, Klongtoey, Bangkok, 10260.
<br/>
<br/>

Email:   dpo@dtgo.com
<br/>
<br/> 

Phone: 02 742 9141
    </p>
    </Container>
    </>
    )
}

export default PDPA;
