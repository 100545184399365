import React  from 'react';
import '../index.css';
import '../main.css';
import { CallToAction } from '../components/CallToAction';
import { Faqs } from '../components/Faqs';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Hero } from '../components/Hero';
import { Pricing } from '../components/Pricing';
import { PrimaryFeatures } from '../components/PrimaryFeatures';
import { SecondaryFeatures } from '../components/SecondaryFeatures';
import { Testimonials } from '../components/Testimonials';

function Home() {
  return (
    <>
      <Header />
      <main>
        <Hero />
      </main>
      <Footer />
    </>
  )
}

export default Home;
